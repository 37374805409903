import { Routes, Route, useNavigate } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import Home from "./pages/Home";
import Motif from "./pages/Motif";
import Logout from "./pages/Logout";
import Results from "./pages/Results";
import NotFound from "./pages/Notfound";
import Dashboard from "./pages/Dashboard";
import Viewresult from "./pages/Viewresult";

import { PageLayout } from "./utils/PageLayout";
import { UserProvider } from "./utils/Usercontext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.scss";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <UserProvider>
        <PageLayout>
          <Pages />
        </PageLayout>
      </UserProvider>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<Home />} />
      <Route path="" component={<NotFound />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/motif" element={<Motif />} />
      <Route path="/results" element={<Results />} />
      <Route path="/viewresult/:id" element={<Viewresult />} />
    </Routes>
  );
}

export default App;
