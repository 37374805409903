import { useState, useRef, useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { useNavigate } from "react-router-dom";
import { Button } from "@element/react-components";

import HomeLogo from "../assets/images/Logo.png";
import BayerLogo from "../assets/images/BayerLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUser,
  faPoll,
  faCheck,
  faXmark,
  faQuestion,
  faBookmark,
  faSignOutAlt,
  faCommentAlt,
  faHourglassEnd,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/Layout.css";

export const PageLayout = (props) => {
  const navigate = useNavigate();
  const isLoggedIn = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState();

  const wrapperRef = useRef(null);
  const profileRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [showprofilemenu, setShowprofilemenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !showNotifications
    ) {
      setShowNotifications(false);
    }
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      !showprofilemenu
    ) {
      setShowprofilemenu(false);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (accounts.length === 0) {
      navigate("/");
    } else {
      setUserName(accounts[0].name);
    }

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [accounts]);

  return (
    <>
      <div className="header">
        <div className="header_logo">
          <a onClick={() => navigate("/")}>
            <img
              src={BayerLogo}
              alt="Bayer Logo"
              className="header_logo_bayer"
            />
          </a>
          <span className="header_logo_line"></span>
          <div className="header_title">
            <a onClick={() => navigate("/")}>
              <img
                src={HomeLogo}
                alt="Home Logo"
                className="header_logo_atlasone"
              />
            </a>
          </div>
        </div>
        {isLoggedIn && (
          <ul className="userheader">
            {window.location.pathname === "/motif" && (
              <li>
                <a onClick={() => navigate("/motif")} className="resultslink">
                  <FontAwesomeIcon icon={faPoll} />
                  &nbsp;Motif
                </a>
              </li>
            )}
            <li>
              <a onClick={() => navigate("/results")} className="resultslink">
                <FontAwesomeIcon icon={faPoll} />
                &nbsp;Results
              </a>
            </li>
            <li ref={wrapperRef}>
              <a
                className="white"
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <FontAwesomeIcon icon={faBell} />
              </a>
              {messages.length > 0 && (
                <span
                  className="notify"
                  onClick={() => setShowNotifications(!showNotifications)}
                >
                  {messages.length}
                </span>
              )}
              {showNotifications && (
                <ul className="notifications">
                  {messages.length > 0 &&
                    messages.map((item, i) => (
                      <li key={i}>
                        <span className="notificationicon">
                          {item.done ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faHourglassEnd} />
                          )}
                        </span>
                        <span className="notificationtext">{item.name}</span>
                        <span
                          className="notificationclose"
                          onClick={() => /*removeTIcket(item.id)*/ {}}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </span>
                      </li>
                    ))}
                  {messages.length === 0 ? (
                    <li className="text-center marginbot50">
                      You have cleared all the notifications
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <Button
                      type="submit"
                      variant="outlined"
                      label="Clear all notification"
                      onClick={() => setMessages([])}
                      className={
                        messages.length > 0
                          ? "btnclearnotify active"
                          : "btnclearnotify disabled"
                      }
                      disabled={messages.length > 0 ? false : true}
                    />
                    {/*
                      <button
                        className="btnclearnotify active"
                        onClick={() => {}}
                      >
                        Clear all notification
                      </button>
                    */}
                  </li>
                </ul>
              )}
            </li>
            <li ref={profileRef}>
              <a
                className="blue"
                onClick={() => setShowprofilemenu(!showprofilemenu)}
              >
                <FontAwesomeIcon icon={faUser} />
              </a>
              {showprofilemenu && (
                <ul className="userprofile">
                  <li>
                    <span className="userprofile_img">
                      <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" />
                    </span>
                    <strong>{userName}</strong>
                  </li>
                  <li>
                    <span className="userprofile_icon">
                      <FontAwesomeIcon icon={faQuestion} />
                    </span>
                    Help & Support
                  </li>
                  <li>
                    <span className="userprofile_icon left25">
                      <FontAwesomeIcon icon={faCommentAlt} />
                    </span>
                    FAQ
                  </li>
                  <li>
                    <span className="userprofile_icon left25">
                      <FontAwesomeIcon icon={faBookmark} />
                    </span>
                    Guides
                  </li>
                  <li>
                    <a onClick={() => handleLogout()}>
                      <span className="userprofile_icon">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                      </span>
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        )}
      </div>
      <div className="container">{props.children}</div>
    </>
  );
};
