import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import axios from "axios";

import { basePath, axiosOptions } from "../utils/constants";
import Resultspaginator from "../helpers/Resultspaginator";
// import results from "./../assets/data/results.json";

const Results = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [username, setUserName] = useState("");

  const getResults = async (user_name = username) => {
    await axios
      .get(`${basePath}jobs`, axiosOptions)
      .then((res) => {
        const temp = res.data.filter((itm) => itm.username === user_name);
        setResults(temp);
      })
      .catch((er) => console.log(er));
  };

  useEffect(() => {
    if (accounts.length === 0 || accounts === undefined) {
      navigate("/");
    } else {
      setUserName(accounts[0].name);
      getResults(accounts[0].name);
    }
  }, []);

  return (
    <>
      <div className="row margintop1">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h4>RESULTS</h4>
        </div>
        <Resultspaginator itemsPerPage="5" items={results} />
      </div>
    </>
  );
};

export default Results;
