import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="row margintop1 text-center">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 margintop1">
          <h2>404! Not found</h2>
          <h6 className="text-center">
            <a onClick={() => navigate("/")}>Click here</a> for home page
          </h6>
        </div>
      </div>
    </>
  );
};

export default NotFound;
