import React, { useState, useEffect } from "react";
import { Textfield, Button } from "@element/react-components";

const Motif = () => {
  const [proseq, setProseq] = useState("");

  const submitIPSeq = () => {};

  useEffect(() => {}, []);

  return (
    <>
      <div className="row margintop1">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h4>Submit MRNA sequence</h4>
          <p>Validate and submit your sequence here</p>
          <p>
            <strong>Please provide a MRNA sequence name</strong>
          </p>
          <Textfield
            variant="outlined"
            id="proteinseqnId"
            name="proteinseq"
            className="fullwidth"
            style={{ width: "100%" }}
            label="Enter sequence*"
            value={proseq}
            placeholder="Enter sequence"
            onChange={(e) => setProseq(e.target.value)}
          />
          <br />
          <Button
            label="GO"
            type="submit"
            variant="outlined"
            className={
              proseq.length === 0 ? "borderbtn" : "borderbtn activebluebtn"
            }
            onClick={() => submitIPSeq()}
          />
        </div>
      </div>
    </>
  );
};

export default Motif;
